import React from "react";
import { Router, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";

import Home from "./views/Home";

import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import NavBar from "./components/NavBar";
initFontAwesome();

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }
  return (
    <Router history={history}>
      <div id="app">
        <NavBar />
        <Switch>
          <PrivateRoute path="/" component={Home} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
