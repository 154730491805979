import React, { useEffect } from "react";
import useSWR from "swr";
import fetcherWithToken from "../utils/fetcher";
import { useAuth0 } from "../react-auth0-spa";
import Loading from "../components/Loading";
import CaseTable, { CaseTableBody } from "../components/CaseTable";

type BankruptcyResponse = {
  id: number;
  case_number: string;
  updated_at: string;
  body: {
    title: string;
    dateFiled: string;
    judge: string;
    court: string;
  };
  saved_to_graph: boolean;
  user_email: string | null;
};

function formatCases(cases: BankruptcyResponse[]) {
  return cases
    .filter((bcyCase) => !bcyCase.saved_to_graph)
    .reduce((acc, curr) => {
      acc.push({
        id: curr.id,
        caseNumber: curr.case_number,
        debtorName: curr.body.title,
        dateFiled: curr.body.dateFiled,
        updatedAt: curr.updated_at,
        lastModifiedBy: curr.user_email,
        judge: curr.body.judge,
        court: curr.body.court,
      });
      return acc;
    }, [] as CaseTableBody[]);
}

export default function Pending() {
  const { loading, isAuthenticated, getIdTokenClaims } = useAuth0();
  const { data, error } = useSWR(
    [
      `${process.env.REACT_APP_API}/pending_bankruptcies`,
      async () => await getIdTokenClaims(),
    ],
    fetcherWithToken,
    { revalidateOnFocus: false } // TODO: Reactivate this option and figure out a way to avoid crashing while showing stale data
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>Unsynced Bankruptcy Cases</h1>
      {error || data?.message ? (
        <div>There was an error fetching cases</div>
      ) : loading || !isAuthenticated || !data ? (
        <Loading />
      ) : (
        <CaseTable data={formatCases(data)} />
      )}
    </div>
  );
}
