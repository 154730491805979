import React from "react";
import { NavLink, Switch } from "react-router-dom";
import {
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useRouter } from "../utils/hooks";
import UpdateIcon from "@material-ui/icons/Update";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import Pending from "./Pending";
import Synced from "./Synced";
import PrivateRoute from "../components/PrivateRoute";
import Case from "./Case";
import NewCase from "./NewCase";

export default function Home() {
  const { navigate } = useRouter();
  return (
    <div>
      <div
        style={{
          width: "6em",
          height: "100%",
          position: "fixed",
          padding: "0.875em",
          borderRight: "1px solid #e4e4e4",
        }}
      >
        <List>
          <ListItem
            button
            key={"b"}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => navigate(`/newCase`)}
            >
              <AddIcon />
            </Fab>
          </ListItem>
          <NavLink
            to="/"
            exact
            activeStyle={{
              fontWeight: "bold",
              color: "#3f51b5",
            }}
            style={{ color: "grey" }}
          >
            <ListItem
              button
              key={"b"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <UpdateIcon />
                <ListItemText>Pending</ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/synced"
            activeStyle={{
              fontWeight: "bold",
              color: "#3f51b5",
            }}
            style={{ color: "grey" }}
          >
            <ListItem
              button
              key={"synced"}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <ListItemIcon
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                <CloudDoneIcon />
                <ListItemText>Synced</ListItemText>
              </ListItemIcon>
            </ListItem>
          </NavLink>
        </List>
      </div>
      <div
        style={{
          padding: "1em 2em 0em 8em",
          height: "calc(100vh + -4.6em)",
          overflow: "scroll",
        }}
      >
        <Switch>
          <PrivateRoute exact path="/newCase" component={NewCase} />
          <PrivateRoute path="/synced/:id" component={Case} />
          <PrivateRoute path="/synced" component={Synced} />
          <PrivateRoute exact path="/" component={Pending} />
          <PrivateRoute path="/:id" component={Case} />
        </Switch>
      </div>
    </div>
  );
}
