import React, { ReactElement } from "react";
import {
  Button,
  CircularProgress,
  ExtendButtonBase,
  ButtonTypeMap,
  PropTypes,
} from "@material-ui/core";

interface Props extends Partial<ExtendButtonBase<ButtonTypeMap<{}, "button">>> {
  loading: boolean;
  onClick?: (e: any) => any;
  message: string;
  type?: "submit" | "reset" | "button";
  ref?: React.RefObject<any>;
  color?: PropTypes.Color;
  variant?: "text" | "outlined" | "contained";
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
}

export default function LoadingButton(props: Props): ReactElement {
  const { loading, onClick, message, type, ref, containerStyle } = props;
  return (
    <div style={{ position: "relative", ...containerStyle }}>
      <Button
        variant={props.variant}
        color={props.color}
        disabled={props.disabled || loading}
        onClick={onClick}
        type={type}
        ref={ref}
        style={{ width: "100%" }}
      >
        {message}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: "-12px",
            marginTop: "-12px",
          }}
        />
      )}
    </div>
  );
}
