import {
  getIdTokenClaimsOptions,
  IdToken,
} from "@auth0/auth0-spa-js/dist/typings/global";
import fetcherWithToken from "./fetcher";
import qs from "querystring";

function insertCase(
  userEmail: string,
  case_number: string,
  body: FormData,
  getToken: (o?: getIdTokenClaimsOptions) => Promise<IdToken>,
  savedToGraph?: boolean
) {
  return fetcherWithToken(
    `${process.env.REACT_APP_API}/bankruptcies`,
    getToken,
    {
      method: "POST",
      body: JSON.stringify({
        user_email: userEmail,
        case_number,
        body,
        saved_to_graph: savedToGraph,
      }),
      headers: {
        "Content-Type": "application/json",
        Prefer: "return=representation",
      },
    }
  );
}

function saveToGraph(
  case_number: string,
  body: FormData,
  getToken: (o?: getIdTokenClaimsOptions) => Promise<IdToken>
) {
  return fetcherWithToken(
    `${process.env.REACT_APP_GRAPH_API}/bankruptcies/cases`,
    getToken,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function getPrefilledCase(
  params: qs.ParsedUrlQueryInput,
  getToken: (o?: getIdTokenClaimsOptions) => Promise<IdToken>
) {
  const queryParams = qs.stringify(params);
  return fetcherWithToken(
    `${process.env.REACT_APP_GRAPH_API}/bankruptcies/cases?${queryParams}`,
    getToken
  );
}
// TODO remove when all the amounts are currency units
function migrateToV4(caseJson: any) {
  return {
    ...caseJson,
    capitalStructure: {
      ...caseJson?.capitalStructure,
      unsecuredDebt: caseJson?.capitalStructure?.unsecuredDebt?.map(
        convertDebtToDebtWithCurrency
      ),
      securedDebt: caseJson?.capitalStructure?.securedDebt?.map(
        convertDebtToDebtWithCurrency
      ),
    },
    unsecuredCreditors: caseJson?.unsecuredCreditors?.map(
      convertUnsecuredCreditors
    ),
  };
}
function convertDebtToDebtWithCurrency(debt: Debt): Debt {
  const amount = debt.outstandingAmount;
  return {
    ...debt,
    outstandingAmount: convertAmountToAmountUnitObject(amount),
  };
}
function convertUnsecuredCreditors(
  creditor: UnsecuredCreditors
): UnsecuredCreditors {
  const amount = creditor.amount;
  return { ...creditor, amount: convertAmountToAmountUnitObject(amount) };
}

function convertAmountToAmountUnitObject(
  amount: Array<CurrencyUnit> | number
): Array<CurrencyUnit> {
  if (typeof amount === "number") {
    return [
      {
        value: amount,
        iso: "USD",
      } as CurrencyUnit,
    ];
  } else {
    return amount;
  }
}
type UnsecuredCreditors = {
  amount: Array<CurrencyUnit> | number;
};
type Debt = {
  outstandingAmount: Array<CurrencyUnit> | number;
};
type CurrencyUnit = {
  iso: string;
  value: number;
};

export { insertCase, saveToGraph, getPrefilledCase, migrateToV4 };
