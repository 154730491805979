import React, { FC } from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import DayJsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

function onRedirectCallback(result: { targetUrl: string | null }) {
  return history.push(result?.targetUrl ?? window.location.pathname);
}

interface CloseSnackbarProps {
  id: string | number | undefined;
}

const DEFAULT_THEME = createMuiTheme({
  overrides: {
    MuiGridListTile: {
      root: {
        width: "100%!important",
      },
    },
  },
});

const CloseButton: FC<CloseSnackbarProps> = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <SnackbarProvider maxSnack={3} action={(key) => <CloseButton id={key} />}>
      <MuiThemeProvider theme={DEFAULT_THEME}>
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </SnackbarProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
