import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs from "dayjs";

function DateSelectorWithOptionals(props: any) {
  const isInitiallyYear = props.value?.length < 5; // years have format YYYY
  const [isYearsOnly, setYearsOnly] = React.useState(isInitiallyYear);

  function handleDateChange(
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) {
    // console.log(props.value);
    props.onChange(value);
  }

  function clearDatePicker() {
    props.onChange(null);
  }

  function handleFormatChange(e: any) {
    if (props.value) {
      const date = dayjs(props.value);
      if (e.target.checked) {
        console.log(date.format("YYYY"));
        props.onChange(date.format("YYYY"));
      } else {
        console.log(date.format("YYYY-MM-DD"));
        props.onChange(date.format("YYYY-MM-DD"));
      }
    } else {
      clearDatePicker();
    }
    // Set state is async, so we are using the original event first and
    // then updating state for its use on the render, this can also be achieved with useEffect
    setYearsOnly(e.target.checked);
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "self-end",
        justifyContent: "space-between",
      }}
    >
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format={isYearsOnly ? "YYYY" : "YYYY-MM-DD"}
        views={isYearsOnly ? ["year"] : ["date"]}
        margin="normal"
        id="date-picker-inline"
        label="Date picker inline"
        value={props.value}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isYearsOnly}
            onChange={handleFormatChange}
            name="dateformat"
            color="primary"
          />
        }
        label="year only"
      />
    </div>
  );
}

export default DateSelectorWithOptionals;
