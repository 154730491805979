import React, { useReducer } from "react";
import useSWR from "swr";
import { useAuth0 } from "../react-auth0-spa";
import { useParams, useHistory, useLocation } from "react-router-dom";
import fetcherWithToken from "../utils/fetcher";
import Loading from "../components/Loading";
import CaseForm from "../components/CaseForm";
import { ISubmitEvent } from "@rjsf/core";
import { useRouter } from "../utils/hooks";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IdToken, getIdTokenClaimsOptions } from "@auth0/auth0-spa-js";
import { saveToGraph, insertCase, migrateToV4 } from "../utils/caseUtils";
import LoadingButton from "../components/LoadingButton";

async function saveCase(
  userEmail: string,
  caseNumber: string,
  body: FormData,
  getToken: (o?: getIdTokenClaimsOptions) => Promise<IdToken>,
  skipGraph?: boolean
) {
  let savedToGraph = false;
  let graphResult;
  let wasSuccesful = skipGraph; // Initialize with skipGraph to yield true automatically if skipping graph step
  const errors = [];
  if (skipGraph !== undefined && !skipGraph) {
    try {
      graphResult = await saveToGraph(caseNumber, body, getToken);
      savedToGraph = true;
      wasSuccesful = true;
    } catch (error) {
      errors.push({ ...error, id: "graph" });
    }
  }

  const caseBody = savedToGraph ? graphResult : body;
  try {
    await insertCase(userEmail, caseNumber, caseBody, getToken, savedToGraph);
    wasSuccesful = wasSuccesful && true;
    return { wasSuccesful, errors };
  } catch (error) {
    errors.push({ ...error, id: "local" });
    return { wasSuccesful: false, errors };
  }
}

type formInitialState = {
  isDisabled: boolean;
  trigerredButton: string | null;
};

type formActions = {
  type: "SAVE_FOR_LATER" | "SYNC_TO_GRAPH" | "CLEAN";
  payload?: any;
};

function formReducer(state: formInitialState, action: formActions) {
  switch (action.type) {
    case "SAVE_FOR_LATER":
      return { trigerredButton: action.type, isDisabled: true };
    case "SYNC_TO_GRAPH":
      return { trigerredButton: action.type, isDisabled: true };
    case "CLEAN":
      return { trigerredButton: null, isDisabled: false };
    default:
      throw new Error();
  }
}

export default function Case() {
  const { getIdTokenClaims, user } = useAuth0();
  const [formState, dispatch] = useReducer(formReducer, {
    isDisabled: false,
    trigerredButton: null,
  });
  const { navigate } = useRouter();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const endpoint = location.pathname.includes("synced")
    ? "synced_bankruptcies"
    : "pending_bankruptcies";
  const { enqueueSnackbar } = useSnackbar();
  const bankruptcyEndpoint = `${process.env.REACT_APP_API}/${endpoint}?case_number=eq.${id}`;
  const { data, error, mutate } = useSWR(
    [bankruptcyEndpoint, async () => await getIdTokenClaims()],
    fetcherWithToken,
    { revalidateOnFocus: false } // TODO: Reactivate this option and figure out a way to avoid crashing while showing stale data
  );
  async function handleFormSubmit(
    e?: ISubmitEvent<any>,
    formDataPayload?: any
  ) {
    const isSaveForLater = e ? false : true;
    const formData = formDataPayload ? formDataPayload : e?.formData;
    isSaveForLater
      ? dispatch({ type: "SAVE_FOR_LATER" })
      : dispatch({ type: "SYNC_TO_GRAPH" });

    const { caseNumber } = formData;
    const { wasSuccesful, errors } = await saveCase(
      user.email,
      caseNumber,
      formData,
      getIdTokenClaims,
      isSaveForLater
    );
    // TODO: Change data object to be an object instead of an array
    const mutatedData = [{ ...data[0], body: formData }];
    if (wasSuccesful) {
      const message = isSaveForLater
        ? "Successfully saved a version of the case"
        : "Successfully saved a version of the case and synced to the graph";
      enqueueSnackbar(message, {
        variant: "success",
        autoHideDuration: 10000,
      });
      // Since we have the updated object we avoid re-fecting to save a get request
      mutate(mutatedData, false);
      return navigate(isSaveForLater ? "/" : "/synced");
    }
    if (errors.length > 1) {
      enqueueSnackbar("There was an error saving the case, please try again", {
        variant: "error",
        autoHideDuration: 10000,
      });
    } else {
      if (errors[0].id === "graph") {
        enqueueSnackbar(
          "There was an error syncing this case to the graph, a version was saved please try to sync again later!",
          {
            variant: "warning",
            autoHideDuration: 10000,
          }
        );
        return navigate("/");
      } else {
        enqueueSnackbar(
          "The case was synced to the graph but there was an error saving a version, please submit again",
          {
            variant: "warning",
            autoHideDuration: 10000,
          }
        );
      }
      // calling this boundede mutate broadcast a revalidation call
      // we know the data was succesfully saved, so re-fetching is less error prone
      // than manually mutating data here
      mutate();
    }
    dispatch({ type: "CLEAN" });
  }

  function renderButtons(formData?: any) {
    const buttonContainerStyle = {
      margin: "1em 0 0 1em",
      flexGrow: 1,
      maxWidth: "300px",
    };
    return (
      <div style={{ display: "flex", justifyContent: "end", flexWrap: "wrap" }}>
        <LoadingButton
          loading={formState.trigerredButton === "SYNC_TO_GRAPH"}
          disabled={formState.isDisabled}
          variant="contained"
          color="primary"
          message="SYNC TO GRAPH"
          type="submit"
          containerStyle={buttonContainerStyle}
        />
        <LoadingButton
          onClick={() => handleFormSubmit(undefined, formData)}
          disabled={formState.isDisabled}
          loading={formState.trigerredButton === "SAVE_FOR_LATER"}
          color="primary"
          variant="outlined"
          message="SAVE FOR LATER"
          containerStyle={buttonContainerStyle}
        />
      </div>
    );
  }

  return (
    <>
      {!data && !error ? (
        <Loading />
      ) : error || !id || data?.message || data?.length < 1 ? (
        <div>There was an error fetching cases</div>
      ) : (
        <>
          <IconButton
            aria-label="go back"
            color="primary"
            onClick={history.goBack}
            size="medium"
          >
            <ArrowBackIcon />
          </IconButton>
          <div
            style={{
              padding: "2em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CaseForm
              schemaName="caseSchema"
              onSubmit={handleFormSubmit}
              onError={(e: any[]) => {
                enqueueSnackbar(
                  `There are ${e.length} error/s on the form, please fix them`,
                  { variant: "error" }
                );
                window.scrollTo(0, 0);
              }} // TODO: Redirect user directly to the error for custom validation
              formData={migrateToV4(data[0].body)}
              renderButtons={renderButtons}
            >
              <div style={{ display: "flex", justifyContent: "end" }}></div>
            </CaseForm>
          </div>
        </>
      )}
    </>
  );
}
