import React, { ReactElement, useState } from "react";
import { JSONSchema7 } from "json-schema";
import Form, {
  FormProps,
  FormValidation,
  ISubmitEvent,
  IChangeEvent,
} from "@rjsf/core";
import { getAllSymbols } from "../utils/currencyUtils";
import DateSelectorWithOptionals from "./DateSelectorWithOptionals";
import MonetaryInput from "./MonetaryInput";

const currenciesSymbol = getAllSymbols();
const caseSchema: JSONSchema7 = {
  title: "Overview",
  type: "object",
  required: [
    "caseNumber",
    "title",
    "naics",
    "court",
    "judge",
    "estimatedNumberOfCreditors",
    "isPrepacked",
    "dateFiled",
    "goalsQuote",
    "goalsCategory",
  ],
  properties: {
    caseNumber: {
      type: "string",
      title: "Case number",
      format: "case-number",
    },
    title: {
      type: "string",
      title: "Title",
    },
    debtorNewsName: {
      type: ["string", "null"],
      title: "Debtor's news name",
    },
    naics: {
      type: "string",
      title: "NAICS",
      minLength: 4,
    },
    court: {
      type: "string",
      title: "Court",
      enum: [
        "Alabama Middle Bankruptcy Court",
        "Alabama Northern Bankruptcy Court",
        "Alabama Southern Bankruptcy Court",
        "Alaska Bankruptcy Court",
        "Arizona Bankruptcy Court",
        "Arkansas Eastern & Western Bankruptcy Court",
        "California Central Bankruptcy Court",
        "California Eastern Bankruptcy Court",
        "California Northern Bankruptcy Court",
        "California Southern Bankruptcy Court",
        "Colorado Bankruptcy Court",
        "Connecticut Bankruptcy Court",
        "Delaware Bankruptcy Court",
        "District of Columbia Bankruptcy Court",
        "Florida Middle Bankruptcy Court",
        "Florida Northern Bankruptcy Court",
        "Florida Southern Bankruptcy Court",
        "Georgia Middle Bankruptcy Court",
        "Georgia Northern Bankruptcy Court",
        "Georgia Southern Bankruptcy Court",
        "Guam Bankruptcy Court",
        "Hawaii Bankruptcy Court",
        "Idaho Bankruptcy Court",
        "Illinois Central Bankruptcy Court",
        "Illinois Northern Bankruptcy Court",
        "Illinois Southern Bankruptcy Court",
        "Indiana Northern Bankruptcy Court",
        "Indiana Southern Bankruptcy Court",
        "Iowa Northern Bankruptcy Court",
        "Iowa Southern Bankruptcy Court",
        "Kansas Bankruptcy Court",
        "Kentucky Eastern Bankruptcy Court",
        "Kentucky Western Bankruptcy Court",
        "Louisiana Eastern Bankruptcy Court",
        "Louisiana Middle Bankruptcy Court",
        "Louisiana Western Bankruptcy Court",
        "Maine Bankruptcy Court",
        "Maryland Bankruptcy Court",
        "Massachusetts Bankruptcy Court",
        "Michigan Eastern Bankruptcy Court",
        "Michigan Western Bankruptcy Court",
        "Minnesota Bankruptcy Court",
        "Mississippi Northern Bankruptcy Court",
        "Mississippi Southern Bankruptcy Court",
        "Missouri Eastern Bankruptcy Court",
        "Missouri Western Bankruptcy Court",
        "Montana Bankruptcy Court",
        "Nebraska Bankruptcy Court",
        "Nevada Bankruptcy Court",
        "New Hampshire Bankruptcy Court",
        "New Jersey Bankruptcy Court",
        "New Mexico Bankruptcy Court",
        "New York Eastern Bankruptcy Court",
        "New York Northern Bankruptcy Court",
        "New York Southern Bankruptcy Court",
        "New York Western Bankruptcy Court",
        "North Carolina Eastern Bankruptcy Court",
        "North Carolina Middle Bankruptcy Court",
        "North Carolina Western Bankruptcy Court",
        "North Dakota Bankruptcy Court",
        "Ohio Northern Bankruptcy Court",
        "Ohio Southern Bankruptcy Court",
        "Oklahoma Eastern Bankruptcy Court",
        "Oklahoma Northern Bankruptcy Court",
        "Oklahoma Western Bankruptcy Court",
        "Oregon Bankruptcy Court",
        "Pennsylvania Eastern Bankruptcy Court",
        "Pennsylvania Middle Bankruptcy Court",
        "Pennsylvania Western Bankruptcy Court",
        "Puerto Rico Bankruptcy Court",
        "Rhode Island Bankruptcy Court",
        "South Carolina Bankruptcy Court",
        "South Dakota Bankruptcy Court",
        "Tennessee Eastern Bankruptcy Court",
        "Tennessee Middle Bankruptcy Court",
        "Tennessee Western Bankruptcy Court",
        "Texas Eastern Bankruptcy Court",
        "Texas Northern Bankruptcy Court",
        "Texas Southern Bankruptcy Court",
        "Texas Western Bankruptcy Court",
        "Utah Bankruptcy Court",
        "Vermont Bankruptcy Court",
        "Virginia Eastern Bankruptcy Court",
        "Virginia Western Bankruptcy Court",
        "Washington Eastern Bankruptcy Court",
        "Washington Western Bankruptcy Court",
        "West Virginia Northern Bankruptcy Court",
        "West Virginia Southern Bankruptcy Court",
        "Wisconsin Eastern Bankruptcy Court",
        "Wisconsin Western Bankruptcy Court",
        "Wyoming Bankruptcy Court",
      ],
    },
    division: {
      type: ["string", "null"],
      title: "Division",
    },
    judge: {
      type: "string",
      title: "Judge",
    },
    isPrepacked: {
      type: "boolean",
      title: "Prepack",
    },
    dateFiled: {
      type: "string",
      format: "date",
      title: "Date Filed",
    },
    estimatedNumberOfCreditors: {
      type: "string",
      enum: [
        "1-49",
        "50-99",
        "100-199",
        "200-999",
        "1000-5000",
        "5001-10000",
        "10001-25000",
        "25001-50000",
        "50001-100000",
        "More than 100000",
      ],
      title: "Estimated number of creditors",
    },
    assets: {
      type: ["number", "null"],
      title: "Assets (USD)",
    },
    liabilities: {
      type: ["number", "null"],
      title: "Liabilities (USD)",
    },
    estimatedAssets: {
      type: ["string", "null"],
      default: "none",
      enum: [
        "none",
        "$0-$50000",
        "$50001-$100000",
        "$100001-$500000",
        "$500001-$1million",
        "$1000001-$10 million",
        "$10000001-$50 million",
        "$50000001-$100 million",
        "$100000001-$500 million",
        "$500000001-$1 billion",
        "$1000000001-$10 billion",
        "$10000000001-$50 billion",
        "More than $50 billion",
      ],
      title: "Estimated Assets (USD)",
    },
    estimatedLiabilities: {
      type: ["string", "null"],
      title: "Estimated liabilities (USD)",
      default: "none",
      enum: [
        "none",
        "$0-$50000",
        "$50001-$100000",
        "$100001-$500000",
        "$500001-$1million",
        "$1000001-$10 million",
        "$10000001-$50 million",
        "$50000001-$100 million",
        "$100000001-$500 million",
        "$500000001-$1 billion",
        "$1000000001-$10 billion",
        "$10000000001-$50 billion",
        "More than $50 billion",
      ],
    },
    representatives: {
      type: "array",
      title: "Debtor's Representatives",
      items: {
        required: ["firmName"],
        type: "object",
        properties: {
          name: {
            type: ["string", "null"],
            title: "Name",
          },
          firmName: {
            type: "string",
            title: "Firm name",
          },
          phone: {
            type: ["string", "null"],
            title: "Phone",
          },
          address: {
            type: ["string"],
            title: "Address",
          },
          zipCode: {
            type: ["string", "null"],
            title: "Post code/Zip code",
          },
          city: {
            type: ["string", "null"],
            title: "City",
          },
          state: {
            type: ["string", "null"],
            title: "State",
          },
          country: {
            type: ["string", "null"],
            title: "Country",
          },
        },
      },
    },
    keyFilings: {
      type: "array",
      title: "Key Filings",
      items: {
        type: "object",
        properties: {
          filingType: {
            type: "string",
            title: "Filing type",
            enum: [
              "Voluntary Petition",
              "First Day Affidavit",
              "Motion for Approval of Post-Petition Financing",
              "Motion For Authorization To Use Cash Collateral",
              "Motion For Joint Administration",
              "Notice Of Agenda for First Day Hearings",
              "Motion To Pay Critical Vendor Claims",
              "Motion To Pay Employee Wages",
              "Motion To Maintain Bank Accounts",
              "Motion To Maintain Existing Customer Programs",
              "Non relevant",
            ],
          },
          link: {
            type: "string",
            title: "Url",
            format: "url",
          },
          filingDate: {
            type: "string",
            format: "date",
            title: "Filing Date",
          },
        },
        required: ["filingType", "link", "filingDate"],
      },
    },
    unsecuredCreditors: {
      type: "array",
      title: "Unsecured Creditors",
      items: {
        type: "object",
        required: ["companyName", "typeOfClaim"],
        properties: {
          companyName: {
            type: "string",
            title: "Creditor",
          },
          address: {
            type: "string",
            title: "Address",
          },
          zipCode: {
            type: ["string", "null"],
            title: "Post code/Zip code",
          },
          city: {
            type: ["string", "null"],
            title: "City",
          },
          state: {
            type: ["string", "null"],
            title: "State",
          },
          country: {
            type: ["string", "null"],
            title: "Country",
          },
          phone: {
            type: ["string", "null"],
            title: "Phone",
          },
          email: {
            type: ["string", "null"],
            title: "Email",
          },
          typeOfClaim: {
            type: "string",
            title: "Type of claim",
          },
          amount: {
            title: "Amount",
            type: ["array"],
            default: [],
            maxItems: 1,
            items: {
              type: "object",
              required: ["iso", "value"],
              properties: {
                iso: {
                  type: "string",
                  title: "currency",
                  default: "USD",
                  enum: currenciesSymbol,
                },
                value: {
                  type: "number",
                },
              },
            },
          },
        },
      },
    },
    capitalStructure: {
      type: "object",
      title: "Capital Structure",
      properties: {
        securedDebt: {
          type: "array",
          title: "Secured debt",
          items: {
            type: "object",
            required: ["instrument"],
            properties: {
              instrument: {
                type: "string",
                title: "Instrument",
              },
              maturity: {
                type: ["string", "null"],
                title: "Maturity",
              },
              outstandingAmount: {
                title: "Outstanding amount",
                type: ["array"],
                default: [],
                maxItems: 1,
                items: {
                  type: "object",
                  required: ["iso", "value"],
                  properties: {
                    iso: {
                      type: "string",
                      title: "currency",
                      default: "USD",
                      enum: currenciesSymbol,
                    },
                    value: {
                      type: "number",
                    },
                  },
                },
              },
            },
          },
        },

        unsecuredDebt: {
          type: "array",
          title: "Unsecured debt",
          items: {
            type: "object",
            required: ["instrument"],
            properties: {
              instrument: {
                type: "string",
                title: "Instrument",
              },
              maturity: {
                type: ["string", "null"],
                title: "Maturity",
              },
              outstandingAmount: {
                title: "Outstanding amount",
                type: "array",
                default: [],
                maxItems: 1,
                items: {
                  type: "object",
                  required: ["iso", "value"],
                  properties: {
                    iso: {
                      type: "string",
                      title: "currency",
                      default: "USD",
                      enum: currenciesSymbol,
                    },
                    value: {
                      type: "number",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    goalsCategory: {
      type: "string",
      enum: [
        "Balance Sheet Restructuring",
        "Asset Sale",
        "Dual Path Restructuring / Sale",
        "Wind Down (also Liquidation)",
        "Tort Resolution",
        "Other",
      ],
      title: "Goals category",
    },
    goalsQuote: {
      type: "string",
      title: "Goals quote",
    },
    corrections: {
      type: "array",
      title: "Corrections",
      items: {
        type: "object",
        required: ["text", "date"],
        properties: {
          text: {
            type: "string",
            title: "Text",
          },
          date: {
            type: "string",
            format: "date",
            title: "Date of correction",
          },
        },
      },
    },
  },
};

const newCaseSchema: JSONSchema7 = {
  title: "Basic data",
  type: "object",
  required: ["caseNumber", "court"],
  properties: {
    caseNumber: {
      type: "string",
      title: "Case number",
      format: "case-number",
    },
    court: {
      type: "string",
      title: "Court",
      enum: [
        "Alabama Middle Bankruptcy Court",
        "Alabama Northern Bankruptcy Court",
        "Alabama Southern Bankruptcy Court",
        "Alaska Bankruptcy Court",
        "Arizona Bankruptcy Court",
        "Arkansas Eastern & Western Bankruptcy Court",
        "California Central Bankruptcy Court",
        "California Eastern Bankruptcy Court",
        "California Northern Bankruptcy Court",
        "California Southern Bankruptcy Court",
        "Colorado Bankruptcy Court",
        "Connecticut Bankruptcy Court",
        "Delaware Bankruptcy Court",
        "District of Columbia Bankruptcy Court",
        "Florida Middle Bankruptcy Court",
        "Florida Northern Bankruptcy Court",
        "Florida Southern Bankruptcy Court",
        "Georgia Middle Bankruptcy Court",
        "Georgia Northern Bankruptcy Court",
        "Georgia Southern Bankruptcy Court",
        "Guam Bankruptcy Court",
        "Hawaii Bankruptcy Court",
        "Idaho Bankruptcy Court",
        "Illinois Central Bankruptcy Court",
        "Illinois Northern Bankruptcy Court",
        "Illinois Southern Bankruptcy Court",
        "Indiana Northern Bankruptcy Court",
        "Indiana Southern Bankruptcy Court",
        "Iowa Northern Bankruptcy Court",
        "Iowa Southern Bankruptcy Court",
        "Kansas Bankruptcy Court",
        "Kentucky Eastern Bankruptcy Court",
        "Kentucky Western Bankruptcy Court",
        "Louisiana Eastern Bankruptcy Court",
        "Louisiana Middle Bankruptcy Court",
        "Louisiana Western Bankruptcy Court",
        "Maine Bankruptcy Court",
        "Maryland Bankruptcy Court",
        "Massachusetts Bankruptcy Court",
        "Michigan Eastern Bankruptcy Court",
        "Michigan Western Bankruptcy Court",
        "Minnesota Bankruptcy Court",
        "Mississippi Northern Bankruptcy Court",
        "Mississippi Southern Bankruptcy Court",
        "Missouri Eastern Bankruptcy Court",
        "Missouri Western Bankruptcy Court",
        "Montana Bankruptcy Court",
        "Nebraska Bankruptcy Court",
        "Nevada Bankruptcy Court",
        "New Hampshire Bankruptcy Court",
        "New Jersey Bankruptcy Court",
        "New Mexico Bankruptcy Court",
        "New York Eastern Bankruptcy Court",
        "New York Northern Bankruptcy Court",
        "New York Southern Bankruptcy Court",
        "New York Western Bankruptcy Court",
        "North Carolina Eastern Bankruptcy Court",
        "North Carolina Middle Bankruptcy Court",
        "North Carolina Western Bankruptcy Court",
        "North Dakota Bankruptcy Court",
        "Ohio Northern Bankruptcy Court",
        "Ohio Southern Bankruptcy Court",
        "Oklahoma Eastern Bankruptcy Court",
        "Oklahoma Northern Bankruptcy Court",
        "Oklahoma Western Bankruptcy Court",
        "Oregon Bankruptcy Court",
        "Pennsylvania Eastern Bankruptcy Court",
        "Pennsylvania Middle Bankruptcy Court",
        "Pennsylvania Western Bankruptcy Court",
        "Puerto Rico Bankruptcy Court",
        "Rhode Island Bankruptcy Court",
        "South Carolina Bankruptcy Court",
        "South Dakota Bankruptcy Court",
        "Tennessee Eastern Bankruptcy Court",
        "Tennessee Middle Bankruptcy Court",
        "Tennessee Western Bankruptcy Court",
        "Texas Eastern Bankruptcy Court",
        "Texas Northern Bankruptcy Court",
        "Texas Southern Bankruptcy Court",
        "Texas Western Bankruptcy Court",
        "Utah Bankruptcy Court",
        "Vermont Bankruptcy Court",
        "Virginia Eastern Bankruptcy Court",
        "Virginia Western Bankruptcy Court",
        "Washington Eastern Bankruptcy Court",
        "Washington Western Bankruptcy Court",
        "West Virginia Northern Bankruptcy Court",
        "West Virginia Southern Bankruptcy Court",
        "Wisconsin Eastern Bankruptcy Court",
        "Wisconsin Western Bankruptcy Court",
        "Wyoming Bankruptcy Court",
      ],
    },
  },
};

const baseUiSchema = {
  caseNumber: {
    "ui:help": "Format: XX-XXXXX",
  },
  debtorNewsName: {
    "ui:help": "if different from case name",
  },
  estimatedAssets: {
    "ui:help": "if total assets not available",
    "ui:emptyValue": "",
  },
  estimatedLiabilities: {
    "ui:help": "if total liabilities not available",
    "ui:emptyValue": "",
  },
  assets: {
    "ui:emptyValue": 0,
    "ui:widget": MonetaryInput,
  },
  liabilities: {
    "ui:emptyValue": null,
    "ui:widget": MonetaryInput,
  },
  isPrepacked: {
    "ui:widget": "select",
  },
  goalsQuote: {
    "ui:widget": "textarea",
  },
  division: {
    "ui:emptyValue": null,
  },
  unsecuredCreditors: {
    items: {
      amount: {
        items: {
          value: {
            "ui:widget": MonetaryInput,
          },
        },
      },
    },
  },
  capitalStructure: {
    securedDebt: {
      items: {
        outstandingAmount: {
          items: {
            value: {
              "ui:widget": MonetaryInput,
            },
          },
        },
        maturity: {
          "ui:widget": DateSelectorWithOptionals,
        },
      },
    },
    unsecuredDebt: {
      items: {
        outstandingAmount: {
          items: {
            value: {
              "ui:widget": MonetaryInput,
            },
          },
        },
        maturity: {
          "ui:widget": DateSelectorWithOptionals,
        },
      },
    },
  },
  corrections: {
    items: {
      text: {
        "ui:widget": "textarea",
        "ui:options": {
          rows: 3,
        },
      },
    },
  },
};

function validate(formData: any, errors: FormValidation) {
  if (formData.estimatedAssets !== "none") {
    if (formData.assets && !isNaN(formData.assets)) {
      errors.estimatedAssets.addError(
        "If exact assets are specified, estimated should be none"
      );
    }
  } else {
    if (!formData.assets && !isNaN(formData.assets)) {
      errors.estimatedAssets.addError(
        "If exact assets are not specified, estimated should be specified"
      );
    }
  }
  if (formData.estimatedLiabilities !== "none") {
    if (formData.liabilities && !isNaN(formData.liabilities)) {
      errors.estimatedLiabilities.addError(
        "If exact liabilities are specified, estimated should be none"
      );
    }
  } else {
    if (!formData.liabilities && !isNaN(formData.liabilities)) {
      errors.estimatedLiabilities.addError(
        "If exact liabilities are not specified, estimated should be specified"
      );
    }
  }
  return errors;
}

const customFormats = {
  "case-number": /([0-9]){2}-([0-9]){5}$/,
  url: /\b(https?|ftp|file):\/\/[A-Za-z0-9+&@#%?=~_|!:,.;]*[A-Za-z0-9+&@#%=~_|]/,
};

const schemas = {
  caseSchema,
  newCaseSchema,
};

interface CustomFormProps extends Partial<FormProps<any>> {
  schemaName?: "caseSchema" | "newCaseSchema";
  schema?: JSONSchema7;
  children?: React.ReactElement;
  validate?: (formData: any, errors: FormValidation) => FormValidation;
  onSubmit?: (e: ISubmitEvent<any>, isProgramatic?: boolean) => any;
  renderButtons?: (formData: any) => JSX.Element;
  onSaveForLater?: (e: any) => void;
}

export default function CaseForm(props: CustomFormProps): ReactElement {
  const [formData, setFormData] = useState(props.formData);
  const [wasProgramaticallySubmitttted, setProgramaticallySubmitted] = useState(
    false
  );

  const { schema, schemaName, renderButtons, onSubmit, onChange } = props;

  function handleSubmit(e: ISubmitEvent<any>) {
    if (onSubmit) {
      onSubmit(e, wasProgramaticallySubmitttted);
      setProgramaticallySubmitted(false);
    }
  }
  function handleOnChange(e: IChangeEvent<any>) {
    if (onChange) {
      onChange(e);
    }
    setFormData(e.formData);
  }

  const schemaToUse = schemaName ? schemas[schemaName] : schema;
  if (schemaToUse) {
    return (
      <div style={{ padding: "2em", maxWidth: "2000px" }}>
        <Form
          schema={schemaToUse}
          uiSchema={baseUiSchema}
          customFormats={customFormats}
          validate={validate}
          noHtml5Validate={true}
          {...props}
          formData={formData}
          onSubmit={handleSubmit}
          onChange={handleOnChange}
        >
          {renderButtons ? renderButtons(formData) : null}
        </Form>
      </div>
    );
  } else {
    return <div>No valid schema specified</div>;
  }
}
