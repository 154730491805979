import React, { memo } from "react";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import { Link } from "react-router-dom";

const caseColumns: MUIDataTableColumnDef[] = [
  {
    name: "id",
    options: {
      display: "false",
      filter: false,
      viewColumns: false,
    },
  },
  {
    name: "caseNumber",
    label: "Case Number",
    options: {
      filter: false,
      customBodyRender: (value) => {
        const location =
          window.location.pathname === "/"
            ? value
            : `${window.location.pathname}/${value}`;
        return <Link to={location}>{value}</Link>;
      },
    },
  },
  {
    name: "debtorName",
    label: "Debtor Name",
    options: {
      filter: false,
    },
  },
  {
    name: "dateFiled",
    label: "Date Filed",
    options: {
      filter: false,
      sortDirection: "desc",
      customBodyRender: (value: string) => {
        const date = Date.parse(value) ? new Date(value).toDateString() : null;
        return <time style={{ whiteSpace: "nowrap" }}>{date}</time>;
      },
    },
  },
  {
    name: "updatedAt",
    label: "Updated At",
    options: {
      filter: false,
      sortDirection: "desc",
      customBodyRender: (value: string) => {
        const date = Date.parse(value)
          ? new Date(value).toLocaleString()
          : null;
        return <time style={{ whiteSpace: "nowrap" }}>{date}</time>;
      },
    },
  },
  {
    name: "lastModifiedBy",
    label: "Last modified by",
  },
  {
    name: "judge",
    label: "Judge",
    options: {
      display: "false",
    },
  },
  {
    name: "court",
    label: "Court",
    options: {
      display: "false",
    },
  },
];

export interface CaseTableBody {
  id: number;
  caseNumber: string;
  debtorName: string;
  dateFiled: string;
  updatedAt: string;
  lastModifiedBy?: string | null;
  judge: string;
  court: string;
}

interface CaseTableProps {
  data: Array<CaseTableBody | string[]>;
  columns?: MUIDataTableColumnDef[];
  options?: MUIDataTableOptions;
  title?: string | React.ReactNode;
}

export default memo(function CaseTable(props: CaseTableProps) {
  return (
    <MUIDataTable
      title={props?.title}
      columns={props.columns ? props.columns : caseColumns}
      options={{
        selectableRows: "none",
        fixedHeaderOptions: {
          xAxis: false,
          yAxis: false,
        },
        responsive: "scrollMaxHeight",
        // Add explicit textLabels for table
        textLabels: {
          body: {
            noMatch: "No matching records found",
            toolTip: "Sort",
          },
          pagination: {
            next: "Next Page",
            previous: "Previous Page",
            rowsPerPage: "Rows per page:",
            displayRows: "of",
          },
          toolbar: {
            search: "Search",
            downloadCsv: "Download CSV",
            print: "Print",
            viewColumns: "View Columns",
            filterTable: "Filter Table",
          },
          filter: {
            all: "All",
            title: "FILTERS",
            reset: "RESET",
          },
          viewColumns: {
            title: "Show Columns",
            titleAria: "Show/Hide Table Columns",
          },
          selectedRows: {
            text: "row(s) selected",
            delete: "Delete",
            deleteAria: "Delete Selected Rows",
          },
        },
      }}
      {...props}
    />
  );
});
