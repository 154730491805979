interface HttpError extends ErrorConstructor {
  message: string;
  response: any;
  status: number;
}

const fetcherWithToken = async (
  url: string,
  getToken: () => any,
  init?: RequestInit
) => {
  const tokenRaw = await getToken();
  return fetch(url, {
    ...init,
    headers: {
      Authorization: `Bearer ${await tokenRaw.__raw}`,
      ...init?.headers,
    },
  }).then((r) => {
    if (r.ok) {
      return r.json();
    } else {
      let err = new Error(
        `Call to ${url} failed with HTTP status code: " ${r.status}`
      ) as HttpError;
      err.response = r;
      err.status = r.status;
      throw err;
    }
  });
};

export default fetcherWithToken;
