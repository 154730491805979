import React from "react";
import NumberFormat from "react-number-format";

function MonetaryInput(props: any) {
  return (
    <NumberFormat
      className="form-control"
      value={props.value}
      displayType={"input"}
      defaultValue={0}
      thousandSeparator={true}
      inputMode="numeric"
      allowEmptyFormatting={true}
      onValueChange={(event: { value: any }) => props.onChange(event.value)}
    />
  );
}

export default MonetaryInput;
