import React, { ReactElement, useState } from "react";
import { useAuth0 } from "../react-auth0-spa";
import Loading from "../components/Loading";
import CaseForm from "../components/CaseForm";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { insertCase, getPrefilledCase } from "../utils/caseUtils";
import {
  getIdTokenClaimsOptions,
  IdToken,
} from "@auth0/auth0-spa-js/dist/typings/global";
import { ISubmitEvent } from "@rjsf/core";
import { useSnackbar } from "notistack";
import { useRouter } from "../utils/hooks";
import LoadingButton from "../components/LoadingButton";

async function saveCase(
  userEmail: string,
  case_number: string,
  court: string,
  getToken: (o?: getIdTokenClaimsOptions) => Promise<IdToken>
) {
  let prefilledCase;
  let wasSuccesful = false;
  const errors = [];
  const params = { case_number, court };
  try {
    prefilledCase = await getPrefilledCase(params, getToken);
    const filteredKeyFilings = prefilledCase.keyFilings.filter(
      (filing: { filingType: string }) => filing.filingType !== "Non relevant"
    );
    prefilledCase = { ...prefilledCase, keyFilings: filteredKeyFilings };
    wasSuccesful = true;
  } catch (error) {
    errors.push({ ...error, id: "linker" });
  }
  const caseBody = prefilledCase ?? params;
  try {
    await insertCase(userEmail, case_number, caseBody, getToken);
    wasSuccesful = wasSuccesful && true;
    return { wasSuccesful, errors };
  } catch (error) {
    errors.push({ ...error, id: "local" });
    return { wasSuccesful: false, errors };
  }
}

export default function NewCase(): ReactElement {
  const { loading, isAuthenticated, user, getIdTokenClaims } = useAuth0();
  const [isFormSubmitLoading, setisFormSubmitLoading] = useState(false);
  const history = useHistory();
  const { navigate } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  function renderButtons(formData?: any) {
    const buttonContainerStyle = {
      margin: "1em 0 0 1em",
      flexGrow: 1,
      maxWidth: "300px",
    };
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <LoadingButton
          loading={isFormSubmitLoading}
          color="primary"
          variant="contained"
          message="Create"
          type="submit"
          containerStyle={buttonContainerStyle}
        />
      </div>
    );
  }

  // TODO: Abstract error messages to a centralized enum
  async function handleFormSubmit(e: ISubmitEvent<any>) {
    setisFormSubmitLoading(true);
    const { caseNumber: case_number, court } = e.formData;
    const { wasSuccesful, errors } = await saveCase(
      user.email,
      case_number,
      court,
      getIdTokenClaims
    );
    if (wasSuccesful) {
      enqueueSnackbar("Successfully saved and prefilled the case", {
        variant: "success",
        autoHideDuration: 10000,
      });
      return navigate(case_number);
    }
    if (errors.length > 1) {
      enqueueSnackbar("There was an error saving the case, please try again", {
        variant: "error",
        autoHideDuration: 10000,
      });
      setisFormSubmitLoading(false);
    } else {
      if (errors[0].id === "linker") {
        enqueueSnackbar("There was an error trying to prefill this case", {
          variant: "warning",
          autoHideDuration: 10000,
        });
        return navigate(case_number);
      } else {
        enqueueSnackbar(
          "There was an issue creating the case, please submit again",
          {
            variant: "warning",
            autoHideDuration: 10000,
          }
        );
        setisFormSubmitLoading(false);
      }
    }
  }
  return (
    <>
      {loading || !isAuthenticated ? (
        <Loading />
      ) : (
        <div style={{ padding: "2em" }}>
          <IconButton
            aria-label="go back"
            color="primary"
            onClick={history.goBack}
            size="medium"
          >
            <ArrowBackIcon />
          </IconButton>
          <h1>New Case</h1>
          <CaseForm
            schemaName="newCaseSchema"
            onSubmit={handleFormSubmit}
            renderButtons={renderButtons}
          />
        </div>
      )}
    </>
  );
}
